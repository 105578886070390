/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseService } from './base.service';
import { ApiResp } from '@app/types/global.type';
import {
  EInvoice,
  EInvoiceInfo,
  EInvoiceListPayload,
  InvoiceCount,
  SchedulerLogsList,
} from '@app/types/taxpayer.type';
import { downloadBlob } from '@app/utils/misc';

export interface CreateEInvoice {
  invType: string;
  docType: string;
  currencyCode: string;
  tranactionType: string;
  currencyExchangeRate: string;
}
interface SubmitEInvoice {
  ids: string[];
}

interface cancelEinvoice {
  reason: string;
  uuids: string[];
}

interface EInvoiceList {
  data: EInvoice[];
  totalRecords: number;
}

interface SchedulerLogs {
  submissionStatsInvoiceResponses: SchedulerLogsList[];
  pageNumber: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
}

export interface SchedulerLogsParams {
  page?: number;
  size?: number;

  tin?: string;
  status?: string;
  submissionDateFrom?: string;
  submissionDateTo?: string;

  // search keys
  submissionId?: string;
  uploadId?: string;
  salesCount?: string;
  purchaseCount?: string;
}

interface EinvoiceEmail {
  message: string;
  subject: string;
  to: string;
  cc: string;
}

export interface VerifyEinvoice {
  id: string;
  uuid: string;
  submissionUid: string;
  longId: string;
  status: string;
  publicURL: string;
  salesInv?: boolean;
  issuedInv: boolean;
}

interface Field {
  name: string;
  included: boolean;
  editable: boolean;
  sequence: number;
}

export interface CustomDownload {
  group: string;
  sequence: number;
  fields: Field[];
  selected: boolean;
  totalCount: number;
  selectedCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class EinvoiceService extends BaseService {
  private draftId = new BehaviorSubject<any>(null);
  draftIdData = this.draftId.asObservable();

  setDraftId(data: any) {
    this.draftId.next(data);
  }

  updateEinvoice(id: string, payload: any) {
    return this.httpClient
      .patch(`/myportal/update/einvoice/${id}`, payload)
      .pipe(map((resp: any) => this.booleanResp(resp)));
  }

  getTinDetail(tin: string) {
    const params = { tin };

    return this.httpClient.get('/myportal/get/tinDetails', { params }).pipe(
      map((resp: any) => {
        return resp.status ? resp.response : [];
      }),
    );
  }

  einvoiceCounts(tin: string, uploaded: boolean, type: string): Observable<InvoiceCount[]> {
    const params = { tin: tin, uploaded: uploaded, type };
    return this.httpClient.get<ApiResp>('/myportal/einvoice/type/count', { params }).pipe(
      map((resp) => {
        return resp.status ? resp.response : [];
      }),
    );
  }

  bulkUploadResultCount(uid: string) {
    const params = { uid };
    return this.httpClient.get<ApiResp>('/myportal/bulkupload/upload/count', { params }).pipe(
      map((resp) => {
        return resp.status ? resp.response : [];
      }),
    );
  }

  bulkUploadCount(uid: string) {
    const params = { uid };
    return this.httpClient.get<ApiResp>('/myportal/bulkupload/submission/count', { params }).pipe(
      map((resp) => {
        return resp.status ? resp.response : [];
      }),
    );
  }

  createEinvoice(
    tin: string,
    pob: string,
    payload: CreateEInvoice,
  ): Observable<{ status: boolean; message: string; response?: any }> {
    const params = { tin, pob };

    return this.httpClient.post('/myportal/generate/einvoice', payload, { params }).pipe(
      map((resp: any) => {
        const status = this.booleanResp(resp);
        return { status, message: resp.message, response: resp.response };
      }),
    );
  }

  getEinvoiceDetails(draftId: string): Observable<EInvoiceInfo> {
    return this.httpClient.get<ApiResp>('/myportal/einvoice/detail/' + draftId).pipe(
      map((resp) => {
        if (!resp.status) this.toastr.error(resp.message, 'Error');
        return resp.status ? resp.response : null;
      }),
    );
  }

  getEinvoiceList(payload: EInvoiceListPayload, type: string): Observable<EInvoiceList> {
    const params = { type: type };
    return this.httpClient.post<ApiResp>('/myportal/einvoices', payload, { params }).pipe(
      map((resp) => {
        if (resp.status) {
          resp.response.data = resp.response.data.map((item: EInvoice) => ({
            ...item,
            lhdn_uuid: item.resp?.uuid,
            lhdn_submissionUID: item.resp?.submissionUid,
            lhdn_longId: item.resp?.longId,
            lhdn_internalId: item.resp?.internalId,
            lhdn_typeName: item.resp?.typeName,
            lhdn_typeVersionName: item.resp?.typeVersionName,
            lhdn_issuerTin: item.resp?.issuerTin,
            lhdn_issuerName: item.resp?.issuerName,
            lhdn_receiverId: item.resp?.receiverId,
            lhdn_receiverName: item.resp?.receiverName,
            lhdn_dateTimeIssued: item.resp?.dateTimeIssued,
            lhdn_dateTimeReceived: item.resp?.dateTimeReceived,
            lhdn_dateTimeValidated: item.resp?.dateTimeValidated,
            lhdn_totalExcludingTax: item.resp?.totalExcludingTax,
            lhdn_totalDiscount: item.resp?.totalDiscount,
            lhdn_totalNetAmount: item.resp?.totalNetAmount,
            lhdn_totalPayableAmount: item.resp?.totalPayableAmount,
            lhdn_rejectRequestDateTime: item.resp?.rejectRequestDateTime,
            lhdn_documentStatusReason: item.resp?.documentStatusReason,
            lhdn_status: item.resp?.status,
            lhdn_sync:
              'isDetailApiCalled' in item
                ? item.isDetailApiCalled === false
                  ? 'Pending'
                  : 'Complete'
                : '',
          }));
          this.toastr.success(resp.message, 'Success');
          return resp.response;
        }
        return null;
      }),
    );
  }

  sendMailEinvoice(
    tin: string,
    type: string,
    payload: EInvoiceListPayload,
    prtId: number,
    invType: string,
  ): Observable<EInvoiceList> {
    const params = { tin, type, prtId, invType };

    return this.httpClient
      .post<ApiResp>('/myportal/einvoices/attachment', payload, { params })
      .pipe(
        map((resp) => {
          return resp.status ? resp.response : null;
        }),
      );
  }

  confirmMail(payload: EinvoiceEmail, tin?: string) {
    const params: any = { tin };

    return this.httpClient.post('/myportal/einvoices/sendMail', payload, { params }).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : [];
      }),
    );
  }

  getBulkUploadEinvoices(data: { tin: string; pobName: string; file: File }): Observable<ApiResp> {
    const params = { tin: data.tin, pob: data.pobName };
    const payload = new FormData();
    payload.append('file', data.file);

    console.log('params', params, payload);
    return this.httpClient
      .post('/myportal/bulk/upload', payload, { params })
      .pipe(map((resp: any) => this.handleFormResp(resp)));
  }

  getUploadHistory(params: { page: number; size: number; tin?: string; pob?: string }) {
    if (params.tin === '') delete params.tin;
    if (params.pob === '') delete params.pob;
    return this.httpClient.get('/myportal/bulkupload/history', { params }).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : [];
      }),
    );
  }

  getEmailHistory(page: number, size: number, tin?: string) {
    const params: any = { page, size };
    if (tin) params.tin = tin;

    return this.httpClient.get('/myportal/einvoices/mail/history', { params }).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : [];
      }),
    );
  }

  submitEinvoice(payload: SubmitEInvoice) {
    return this.httpClient.post('/myportal/submit/einvoice', payload).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : [];
      }),
    );
  }

  cancelRejectEinvoice(action: 'reject' | 'cancel', payload: cancelEinvoice) {
    return this.httpClient.put(`/myportal/${action}/einvoice`, payload).pipe(
      map((resp: any) => {
        if (!resp.status) this.toastr.error(resp.message, 'Error');
        return resp.status ? resp.response : null;
      }),
    );
  }

  cancelEinvoice(payload: cancelEinvoice) {
    return this.httpClient.put(`/myportal/cancel/einvoice`, payload).pipe(
      map((resp: any) => {
        if (resp.status) {
          const { message, response } = resp;
          const statusMessages =
            response?.response
              ?.map(({ uuid, status }: { uuid: string; status: string }) => `${uuid} : ${status}`)
              .join('\n') || '';

          const successMessage = statusMessages ? `${message}\n${statusMessages}` : message;

          this.toastr.success(successMessage, 'Success');
        } else {
          this.toastr.error(resp.message, 'Error');
        }

        return resp.status ? resp.response : [];
      }),
    );
  }

  rejectEinvoice(payload: cancelEinvoice) {
    return this.httpClient.put(`/myportal/reject/einvoice`, payload).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        else this.toastr.error(resp.message, 'Error');

        return resp.status ? resp.response : [];
      }),
    );
  }

  deleteEinvoice(ids: string) {
    const params = { ids };
    return this.httpClient.delete(`/myportal/einvoices`, { params }).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        else this.toastr.error(resp.message, 'Error');

        return resp.status;
      }),
    );
  }

  downloadBulkEinvoiceFile(uid: string) {
    const params: any = { uid };

    return this.httpClient.get<ApiResp>('/myportal/download/bulk/csv', { params }).pipe(
      map((resp: ApiResp) => {
        if (resp.status) {
          const message = resp.response.status ? 'File downloaded!' : 'Download is in progress!';
          const title = resp.response.status ? 'Success' : 'Info';

          this.toastr[resp.response.status ? 'success' : 'info'](message, title);
        } else {
          this.toastr.error(resp.message);
        }
        return resp.status && resp.response.status ? resp.response : null;
      }),
    );
  }

  downloadBulkEinvoiceFileJson(uid: string) {
    const params: any = { uid };

    return this.httpClient.get<ApiResp>('/myportal/download/bulk/json', { params }).pipe(
      map((resp: ApiResp) => {
        if (resp.status) {
          const message = resp.response.status ? 'File downloaded!' : 'Download is in progress!';
          const title = resp.response.status ? 'Success' : 'Info';

          this.toastr[resp.response.status ? 'success' : 'info'](message, title);
        } else {
          this.toastr.error(resp.message);
        }

        return resp.status && resp.response.status ? resp.response : null;
      }),
    );
  }

  downloadViewEinvoice(data: { payload: EInvoiceListPayload; type: string; fileName: string }) {
    const params = { type: data.type };

    return this.httpClient
      .post('/myportal/download/einvoice', data.payload, { params, responseType: 'blob' })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', data.fileName)));
  }

  printEInvoicePdf(
    payload: EInvoiceListPayload,
    tin: string,
    merge: boolean,
    prtId: number,
    type: string,
  ): Observable<EInvoiceList> {
    const params = { tin, merge, prtId, type };

    return this.httpClient.post<ApiResp>('/myportal/einvoices/print/pdf', payload, { params }).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : null;
      }),
    );
  }

  printHistory(page: number, size: number, tin?: string) {
    const params: any = { page, size };
    if (tin) params.tin = tin;

    return this.httpClient.get('/myportal/einvoices/print/history', { params }).pipe(
      map((resp: any) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : [];
      }),
    );
  }

  verifyEinvoice(uuid: string, tin: string): Observable<ApiResp> {
    return this.httpClient
      .get<ApiResp>(`/myportal/verify/einvoice`, {
        params: { uuid: uuid, tin: tin },
      })
      .pipe(map((resp) => this.handleResp(resp)));
  }

  getPrintData() {
    return this.httpClient
      .get<ApiResp>(`/myportal/einvoices/print/templates`)
      .pipe(map((res) => res.response));
  }

  getSchedulerHistory(payload: SchedulerLogsParams): Observable<SchedulerLogs> {
    const params = { ...payload };
    return this.httpClient.get<ApiResp>(`/myportal/schedular-stats`, { params }).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status ? resp.response : null;
      }),
    );
  }

  downloadSchedulerStats(submissionIds: string[]) {
    const params = { submissionIds };
    return this.httpClient
      .post('/myportal/schedular-stats/download', params, { responseType: 'blob' })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', 'SchedulerStats.csv')));
  }

  getCustomDownloadFields() {
    return this.httpClient
      .get<ApiResp>(`/myportal/download/fields`)
      .pipe(map((res) => res.response));
  }

  updateCustomDownloadFields(payload: CustomDownload[]) {
    return this.httpClient.post<ApiResp>('/myportal/download/fields', payload).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        return resp.status;
      }),
    );
  }

  //refresh incoming invoice
  getRefreshInvoice(payload: { uuid: string | undefined; tin: string }) {
    return this.httpClient.post<ApiResp>('/myportal/refresh/incoming/invoice', payload).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        else this.toastr.error(resp.message, 'Error', resp.response);
        return resp.status;
      }),
    );
  }

  getCopy(payload: { id: string }, type: string) {
    const params = { type };
    return this.httpClient.post<ApiResp>('/myportal/copy/invoice', payload, { params }).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message, 'Success');
        else this.toastr.error(resp.message, 'Error');
        return resp.status;
      }),
    );
  }
}
